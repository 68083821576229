<template>
  <LoadingIndicator class="form-loading" v-if="loadingQuestions"/>
  <form v-else class="form" novalidate spellcheck="false">
    <template v-if="config.card">
      <div v-if="config.virtual_card === 2" class="form__item" :class="{ 'form__item--disabled': config.card === 2 }">
        <CheckboxComponent v-model="hasCard" :message="hasCard ? null : 'Выпустим виртуальную карту'" circle no-margin>
          <span> У меня есть карта </span>
        </CheckboxComponent>
      </div>
      <div v-if="hasCard" class="form__item">
        <InputComponent
          v-model="form.card.value"
          :error-message="form.card.error"
          title="Номер карты"
          mask="#### #### #### ####"
          :required="config.card === 2"
          @input="form.card.error = null"
        />
      </div>
    </template>
    <div class="form__item">
			<span v-if="emailConfirmDelayed">{{ form.email.value }}</span>
      <InputComponent
				v-else
        v-model="form.email.value"
        :error-message="form.email.error"
        :success-message="form.email.success"
        title="E-mail"
        :disabled="emailCodeSent || !!form.email.success"
        :required="config.email === 2"
        @input="form.email.error = null"
      />
    </div>
    <template v-if="emailCodeSent">
      <div class="form__item form__item--row">
        <InputComponent
          v-model="form.emailConfirmCode.value"
          :error-message="form.emailConfirmCode.error"
          title="Код"
          :mask="codeMask"
        />
        <button
          type="button"
          @click.prevent="emailSubmit"
          class="btn-main btn--sm"
          :disabled="!form.emailConfirmCode.value || form.emailConfirmCode.value.length !== emailCodeLength"
        >
          Подтвердить
        </button>
      </div>
      <div class="form__message" v-if="emailMessage">{{ emailMessage }}</div>
      <div v-if="timer > 0" class="form__message">Повторная отправка кода будет доступна через: {{ timer }} секунд</div>
    </template>
    <div class="form__error" v-if="emailError">{{ emailError }}</div>
    <template v-if="!emailError && emailCodeSent">
      <button
        v-if="timer <= 0"
        @click.prevent="resendEmailCode"
        class="btn-main btn-auto btn--sm"
        type="button"
        :disabled="timer > 0"
      >
        <template>Отправить код еще раз</template>
      </button>
      <div
        class="form__actions form__actions--wrap"
      >
        <button
          v-if="emailCodeSent"
          @click.prevent="emailCodeSent = false"
          class="btn-main btn-auto btn--sm"
          type="button"
        >
          <template>Ввести другой E-mail</template>
        </button>
        <button
          v-if="config.email_confirm === 1"
          type="button"
          @click.prevent="emailConfirmAfter"
          class="btn-main btn--sm"
        >
          Подтвердить позже
        </button>
      </div>
    </template>
    <div v-for="(group, i) in questions_data" :key="i" class="form__group">
      <h3 class="title">{{ group.name }}</h3>
      <span class="subtitle subtitle--orange" v-if="group.questions.find(i => i.logicalName === 'Address')">
        {{ bonusMessage }}
      </span>
      <span class="subtitle" v-if="i === 0">
        Поля, отмеченные <span class="required__symbol">*</span> обязательны для заполнения
      </span>
      <div class="form__items">
        <component
          v-for="(question, index) in group.questions.filter(i => i.answer)"
          :key="index"
          :is="getComponent(question)"
          :title="question.name"
          v-model="question.answer.value"
          :type="getType(question)"
          :required="isRequired(question)"
          :readonly="question.isReadOnly"
          :visible="isVisible(question)"
          :multiple="question.isMultiSelect"
          :options="question.fixedAnswers"
          :error-message="question.errorMessage"
          :isDouble="question.questionType === 'Double'"
          :fio="fio"
          @input="updateData(question, $event)"
        >
          <DadataComponent
            v-model.trim="question.answer.value"
            v-if="question.logicalName === 'Address'"
            title="Адрес"
            @setActive="activeAddress = $event"
          />
        </component>
      </div>
    </div>
    <div class="form__group">
      <CheckboxComponent v-model="form.rules">
        <span>
          Я согласен с
          <a href="https://ivlev-chef.ru/pravila-programmy-loialnosti/" target="_blank">
            Правилами программы лояльности
          </a>
        </span>
      </CheckboxComponent>
      <CheckboxComponent
        v-if="needSubscription"
        v-model="form.subscription"
        notice="Согласие можно отменить или выбрать желаемый вид рассылки, позвонив в контакт-центр по телефону 8-800-25-000-25 (звонок бесплатный по РФ)"
      >
        <span>
          Согласен(на) на получение e-mail, sms и иных видов рассылок информационного и рекламного содержания
        </span>
      </CheckboxComponent>
    </div>
    <div class="form__error" v-if="error">{{ error }}</div>
    <div class="form__actions">
      <button @click.prevent="prevStep" type="button" class="btn-main">
        Назад
      </button>
      <button @click.prevent="formSubmit" class="btn-main" :disabled="loading || !form.rules || emailCodeSent">
        <LoadingIndicator v-if="loading"/>
        <template v-else>Отправить</template>
      </button>
    </div>
  </form>
</template>

<script>
import LoadingIndicator from "@/components/LoadingIndicator";
import InputComponent from "@/components/form/InputComponent";
import RadioComponent from "@/components/form/RadioComponent";
import SelectComponent from "@/components/form/SelectComponent";
import CheckboxComponent from "@/components/form/CheckboxComponent";
import DateComponent from "@/components/form/DateComponent";
import DadataComponent from "@/components/DadataComponent";
import ErrorModal from "@/components/modals/components/ErrorModal.vue";
import moment from "moment";
import axios from "axios";
import el from "vue2-datepicker/locale/es/el";
// import axios from "axios";

export default {
  name: "QuestionsComponent",
  props: {
    codes: Object,
    isBonusCard: Boolean,
  },
  data() {
    return {
      emailCodeLength: 6,
      errorMessage: "Обязательное поле",
      bonusMessage: "Получите дополнительные бонусы за заполнение данных",
      loading: false,
      loadingQuestions: true,
      error: null,
      emailError: null,
      subConfirm: false,
      hasCard: false,
      timer: this.$store.state.config.code_timer,
      interval: null,
      emailCodeSent: false, // код подтверждения отправлен на почту
      emailMessage: null,
      emailConfirmDelayed: false, // подтверждение почты потом
      fio: {
        first: "",
        middle: "",
        last: "",
      },
      form: {
        card: {
          value: null,
          error: null,
        },
        cvcCode: null,
        email: {
          value: null,
          error: null,
          success: null
        },
        emailConfirmCode: {
          value: null,
          error: null
        },
        rules: true,
        subscription: true,
      },
      answers: [],
      activeAddress: null,
      needSubscription: true,
      // aifi_question: {},
    }
  },
  async beforeCreate() {
    await this.$store.dispatch('USER_SUBSCRIPTIONS', {
      token: localStorage.getItem("auth-token")
    }).then(({ data }) => {
      if (data.result && data.result.state === "Success") {
        this.needSubscription = false;
        if (data && data.data && data.data.length) {
          this.needSubscription = data.data.some(item => !item.selected);
        } else {
          this.needSubscription = true;
        }
      }
    })
    await this.$store.dispatch('GET_QUESTIONS', {
      token: localStorage.getItem("auth-token")
    })
      .then(() => {
        this.loadingQuestions = false;
        if (this.questions_data && this.questions_data.length) {
          this.questions_data.forEach(group => {
            if (group.questions && group.questions.length) {
              this.answers.push(...group.questions.map(question => {
                // answer.value пусто, но есть ответ в fixedAnswerIds
                // то беру оттуда и сохраняю в answer.value
                if (
                    question.answer &&
                    !question.answer.value &&
                    question.answer.fixedAnswerIds &&
                    question.answer.fixedAnswerIds.length === 1
                ) {
                  question.answer.value = question.answer.fixedAnswerIds[0]
                }
                return question.answer
              }))
            }
          })
        }
      })
      .catch(() => {
        this.loadingQuestions = false;
      })
  },
  // created() {
  //   this.setAifi()
  // },
  mounted() {
    this.$nextTick(() => {
      if (this.config.card === 0 && this.config.virtual_card === 2) {
        this.$store.state._modals = []
        this.$store.state._modals.push({
          component: ErrorModal,
          options: {
            center: true,
          },
          notClosable: true
        })
      }
    })
    setTimeout(() => {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("card")) {
        this.form.card.value = urlParams.get("card")
      }
    }, 0)
  },
  beforeDestroy() {
    this.$store.state.questions_data = []
  },
  watch: {
    "form.rules"() {
      this.error = this.form.rules
          ? null
          : "Нужно принять правила ПЛ и обработку персональных данных для продолжения регистрации";
    },
  },
  computed: {
    questions_data() {
      return this.$store.state.questions_data
    },
    config() {
      return this.$store.state.config
    },
    codeMask() {
      return "#".repeat(this.emailCodeLength)
    },
    needVirtualCard() {
      if (this.config.virtual_card === 0) {
        return false
      }
      if (this.config.virtual_card === 1) {
        return true
      }
      if (this.config.virtual_card === 2) {
        return !this.hasCard || (this.hasCard && !this.form.card.value)
      }
    }
  },
  methods: {
    sendEmailCode() {
      this.emailCodeSent = true;
      this.emailError = null
      this.$store.dispatch("SET_EMAIL", {
        data: { email: this.form.email.value },
        token: localStorage.getItem("auth-token")
      })
        .then(({ data }) => {
          if (data && data.data && data.data.codeLength && data.result && data.result.state === "Success") {
            this.emailCodeLength = data.data.codeLength
            this.emailError = null
            this.emailMessage = "Введите код подтверждения, отправленный на E-mail: " + this.form.email.value
            this.timer = this.$store.state.config.code_timer
            this.countdown()
            window.scrollTo(0, 0)
          } else if (data.result && data.result.state === "Error") {
            this.emailError = data.result.message
            this.timer = 0
          }
        })
        .finally((e) => {
          this.loading = false
        })
    },
    resendEmailCode() {
      this.$store.dispatch("SEND_EMAIL_CODE", {
        data: { },
        token: localStorage.getItem("auth-token")
      })
        .then(({ data }) => {
          if (data.result && data.result.state === "Success") {
            this.timer = this.$store.state.config.code_timer
            this.countdown()
          } else if (data.result && data.result.state === "Error") {
            this.emailError = data.result.message
          }
        })
    },
    emailSubmit() {
      this.$store.dispatch("EMAIL_CONFIRM", {
        data: {
          confirmCode: this.form.emailConfirmCode.value,
          password: this.form.emailConfirmCode.value,
        },
        token: localStorage.getItem("auth-token")
      })
        .then(({ data }) => {
          if (data.result && data.result.state === "Success") {
            this.emailCodeSent = false
            this.form.email.success = "Почта подтверждена"
          } else if (data.result.state === "Error") {
            this.form.email.error = data.result.message
          } else if (data.result.state === "ValidationError") {
            this.emailCodeSent = true
            this.countdown()
            this.error = "Ошибка. Введите ранее отправленный код"
          }
        })
        .catch(() => {
        });
    },
		emailConfirmAfter() {
			this.emailCodeSent = false
			this.emailConfirmDelayed = true
		},
    countErrors() {
      let errors = 0;
      if (this.form.card.error) {
        errors++
      }
      if (this.form.email.error) {
        errors++
      }
      this.questions_data.forEach(d => {
        d.questions.forEach(answer => {
          if (answer.errorMessage) errors++
        })
      })
      return errors;
    },
    isEmailValid(email) {
      return String(email)
        .toLowerCase()
        .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    isRequired(question) {
      return this.$store.state.config.required_questions.includes(question.logicalName) || question.isRequired;
    },
    isVisible(question) {
      return this.$store.state.config.visible_questions.includes(question.logicalName) || question.isVisibleInRegistration;
    },
    // инициализация скрытого вопроса для адреса
    // setAifi() {
    //   this.aifi_question = JSON.parse(JSON.stringify(this.$store.state.config.aifi_question))
    // },
    prevStep() {
      if (this.isBonusCard) {
        this.$emit("changeStep", this.codes.CARD_SET_CODE)
      } else {
        if (this.$store.state.config.email) {
          this.$emit("changeStep", this.codes.QUESTIONS_CODE)
        } else if (this.$store.state.config.card) {
          this.$emit("changeStep", this.codes.QUESTIONS_CODE)
        } else {
          this.$emit("changeStep", this.codes.PASSWORD_CHANGE_CODE)
        }
      }
    },
    // обновление данных после ввода
    updateData(question) {
      let answer = question.answer
      this.answers.splice(this.answers.findIndex(a => answer && a.questionId === answer.questionId), 1, answer)
      if (question.logicalName === "FirstName") {
        this.fio.first = question.answer.value
      } else if (question.logicalName === "LastName") {
        this.fio.last = question.answer.value
      } else if (question.logicalName === "PatronymicName") {
        this.fio.middle = question.answer.value
      } else if (question.logicalName === "Address") {
        this.setAifi()
      }
      question.errorMessage = null
    },
    getComponent(question) {
      switch (question.questionType) {
        case "String":
        case "Double":
          return InputComponent;
        case "Date":
          return DateComponent;
        case "Select": {
          if (question.logicalName === "Sex") {
            return RadioComponent
          }
          return SelectComponent
        }
        case "Int":
          return InputComponent;
        case "Boolean":
          return CheckboxComponent;
        default:
          return InputComponent
      }
    },
    getType(question) {
      switch (question.questionType) {
        case "String":
        case "Double":
          return "text";
        case "Date":
          return "date";
        case "Int":
          return "number";
        case "Select":
          return "radio";
        case "Boolean":
          return "checkbox";
        default:
          return "text"
      }
    },
    countdown() {
      clearInterval(this.interval)
      this.timer = this.$store.state.config.code_timer
      this.interval = setInterval(() => {
        if (this.timer <= 0) {
          clearInterval(this.interval)
          return
        }
        this.timer -= 1
      }, 1000)
    },
    cardSubmit() {
      this.$store.dispatch("SET_CARD", {
        data: {
          cardNumber: this.form.card.value ? this.form.card.value.replace(/[^\d]/g, '') : null,
          cvcCode: this.form.cvcCode
        },
        token: localStorage.getItem("auth-token")
      })
				.then(({ data }) => {
					if (data.result.message) {
						this.form.card.error = data.result.message
					} else {
						this.tryFinishRegistration()
					}
				})
				.finally(() => {
					this.loading = false
				})
    },
		emitVirtualCard() {
			this.$store.dispatch("EMIT_VIRTUAL", {
				token: localStorage.getItem("auth-token")
			})
				.then(({ data }) => {
					if (data.result && data.result.state === "Error") {
						this.error = data.result.message
					} else {
						this.tryFinishRegistration()
					}
				})
				.catch((e) => {
					console.log('EMIT_VIRTUAL error', e)
				})
				.finally(() => {
					this.loading = false
				})
		},
    formSubmit() {
      if (!this.loading) {
        if (this.form.rules) {
          this.loading = true
          this.resetValidation()
          this.checkValidation()
          if (!this.countErrors()) {
						if (!this.emailConfirmDelayed &&
							this.form.email.value &&
							!this.emailCodeSent &&
							!this.form.email.success
						) {
              this.sendEmailCode()
            }

            if (this.emailCodeSent) return;
            if (this.form.subscription) {
              // подписка на рассылку
              this.$store.dispatch("SUBSCRIPTIONS_CONFIRM", {
                token: localStorage.getItem("auth-token")
              })
            }
            // пользовательское соглашение и персональные данные
            this.$store.dispatch("ACCEPT_TENDER_OFFER", {
              token: localStorage.getItem("auth-token")
            }).then(() => {
              // если это select то меняем value
              let answersChanged = JSON.parse(JSON.stringify(this.answers))
              answersChanged.forEach(answer => {
                let question = null;
                if (this.questions_data) {
                  this.questions_data.forEach(q => {
                    q.questions.forEach(i => {
                      if (i.id === answer.questionId) {
                        question = i
                        return null;
                      }
                    })
                  })
                }

                if (question) {
                  // если пришло поле для формы "utm" и есть query param UTM, то добавляем скрытое поле и записываем туда источник перезода юзера
                  if (question.logicalName === "utm" && this.$route?.query?.UTM) {
                    answer.value = this.$route.query.UTM;
                  }
                  if (question && question.questionType === "Date") {
                    if (answer.value) {
                      // костыли, потому что отправляю один формат, апи принимает другой, а возвращает третий
                      let value = answer.value
                      answer.value = moment(value, "DD.MM.YYYY").format("YYYY-MM-DD")
                      if (isNaN(Date.parse(answer.value))) {
                        answer.value = moment(value).format("YYYY-MM-DD")
                      }
                    } else {
                      answer.value = null
                    }
                  }

                  // если адрес введен правильно
                  // if (question && question.logicalName === "Address" && !question.errorMessage) {
                  //   if (this.isRequired(question) || question.answer.value) {
                  //     this.aifi_question.value = "true"
                  //   }
                  // }
                  // если это селект с множественным выбором, то отправляем массив id выбранных значений в fixedAnswerIds
                  if (question && question.isMultiSelect) {
                    answer.fixedAnswerIds = answer.value.map(an => an.id)
                    answer.value = answer.value.map(an => an.id)
                  }
                  // если это селект с единственным выбором, то отправляем id выбранного значения в fixedAnswerIds
                  else if (answer && answer.value && typeof answer.value === "object") {
                    answer.value = answer.value.id
                    answer.fixedAnswerIds = [answer.value]
                  }
                  // если есть выбор из нескольких значений в fixedAnswers, то отправляем выбранный элемент в массиве fixedAnswerIds
                  else if (answer && answer.value && answer.fixedAnswerIds !== null) {
                    answer.fixedAnswerIds = [answer.value]
                  }
                }
              })
              answersChanged = this.unique(answersChanged)
              // if (this.aifi_question && this.aifi_question.value === "true") {
              //   answersChanged.push(this.aifi_question)
              // }
              this.$store.dispatch("POST_ANSWERS", {
                data: answersChanged,
                token: localStorage.getItem("auth-token")
              })
                .then(({ data }) => {
                  if (data.data && data.data.errors && data.data.errors.length) {
                    data.data.errors.forEach(err => {
                      let index = -1;
                      this.questions_data.forEach(q => {
                        index = q.questions.findIndex(q => q.id == err.idQuestion)
                        if (index !== -1) {
                          q.questions[index].errorMessage = err.errors[0]
                        }
                      })
                    })
										this.loading = false
                  } else if (data.result && data.result.state === "Success") {
                    if (this.needVirtualCard) {
                      this.emitVirtualCard()
                    } else if (this.hasCard && this.form.card.value) {
											this.cardSubmit()
										}
                  } else if (data.result && data.result.state === "Error") {
                    this.error = data.result.message;
										this.loading = false
                  }
                })
                .catch((e) => {
                  this.loading = false
                });
            })
          } else {
            this.loading = false;
          }
        }
      }
    },
    tryFinishRegistration() {
      this.$store.dispatch("TRY_FINISH_REGISTRATION", {
        token: localStorage.getItem("auth-token")
      }).then(() => {
        if (this.isBonusCard) {
          this.$store.dispatch('GET_EMAIL', {
            token: localStorage.getItem("auth-token")
          })
            .then(({ data }) => {
              if (data.data && data.data.currentEmail && data.result.state === "Success") {
                this.$emit("changeStep", this.$store.state.codes.bonus_cards.FINISH_CODE)
              } else {
                this.$emit("changeStep", this.$store.state.codes.bonus_cards.EMAIL_CHANGE)
              }
            })
            .catch(() => {
              this.$emit("changeStep", this.$store.state.codes.bonus_cards.EMAIL_CHANGE)
            })
        } else {
          this.$emit('changeStep', this.codes.FINISH_CODE)
          const urlParams = new URLSearchParams(window.location.search);
          urlParams.delete("card")
          history.replaceState(null, "", location.pathname + urlParams);
        }
      })
    },
    checkValidation() {
      // карта
      if (this.form.card.value) {
        let parsedCard = this.form.card.value.replace(/\D/g, '')
        if (parsedCard.length !== 16) {
          this.form.card.error = "Неправильный ввод карты"
        }
      } else if (this.config.card === 2) {
        this.form.card.error = this.errorMessage
      }
      // почта
      if (this.form.email.value) {
        if (!this.isEmailValid(this.form.email.value)) {
          this.form.email.error = "Неправильный ввод email"
        }
      } else if (this.config.email === 2) {
        this.form.email.error = this.errorMessage
      }

      this.questions_data.forEach(d => {
        d.questions.forEach(question => {
          if (!question.answer.value) {
            if (this.isRequired(question)) {
              question.errorMessage = this.errorMessage;
            }
          }
          // если это адрес, то проверяю на корректный ввод
          else if (question.logicalName === "Address") {
            if (this.activeAddress) {
              if (!this.activeAddress.data?.street) {
                question.errorMessage = "Добавьте улицу"
              } else if (!this.activeAddress.data?.house) {
                question.errorMessage = "Добавьте дом"
              }
              // else {
              //   // если адрес введен правильно
              //   this.aifi_question.value = "true"
              // }
            } else {
              question.errorMessage = "Выберите адрес из выпадающего списка"
            }
          }
        })
      })
    },
    resetValidation() {
      this.error = null
      this.form.card.error = null
      this.form.email.error = null
      this.form.emailConfirmCode.error = null
      if (
        this.questions_data &&
        this.questions_data.length
      ) {
        this.questions_data.forEach(d => {
          d.questions.forEach(item => {
            item["errorMessage"] = null
          })
        })
      }
    },
    unique(arr) {
      let result = [];
      for (let item of arr) {
        if (!result.find(i => item && i.questionId === item.questionId)) {
          result.push(item);
        }
      }
      return result;
    },
  },
  components: {
    ErrorModal,
    InputComponent,
    DadataComponent,
    CheckboxComponent,
    LoadingIndicator,
  }
}
</script>
